import React from "react";
import { Provider } from "./context";
// import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import Gallery from "./components/Gallery";
import "./App.css";

const styleMain = {
  height: "100vh"
};

function App() {
  return (
    <Provider>
      <Router>
        <div className="App" style={styleMain}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/portfolio/:category" component={Portfolio} />
            <Route
              exact
              path="/portfolio/:category/:imgid"
              component={Gallery}
            />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/gallery" component={Gallery} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;

import React, { Component } from "react";
import Int1 from "./img/int1.jpg";
import Int2 from "./img/int2.jpg";
import Int3 from "./img/int3.jpg";
import Int4 from "./img/int4.jpg";
import Int5 from "./img/int5.jpg";
import Int6 from "./img/int6.jpg";
import Int10 from "./img/int10.jpg";
import Int11 from "./img/int11.jpg";
import Int12 from "./img/int12.jpg";
import Int13 from "./img/int13.jpg";
import Int14 from "./img/int14.jpg";

import Ext1 from "./img/ext1.jpg";
import Ext2 from "./img/ext2.jpg";
import Ext3 from "./img/ext3.jpg";
import Ext4 from "./img/ext4.jpg";
import Ext5 from "./img/ext5.jpg";
import Ext6 from "./img/ext6.jpg";

import Dec1 from "./img/dec1.jpg";
import Dec2 from "./img/dec2.jpg";

const Context = React.createContext();

export class Provider extends Component {
  state = {
    images: [
      {
        id: 1,
        imgName: Int1,
        imgType: "interior",
      },
      {
        id: 2,
        imgName: Int2,
        imgType: "interior",
      },
      {
        id: 3,
        imgName: Int3,
        imgType: "interior",
      },
      {
        id: 4,
        imgName: Int4,
        imgType: "interior",
      },
      {
        id: 5,
        imgName: Int5,
        imgType: "interior",
      },
      {
        id: 6,
        imgName: Int6,
        imgType: "interior",
      },
      {
        id: 7,
        imgName: Ext1,
        imgType: "exterior",
      },
      {
        id: 8,
        imgName: Ext2,
        imgType: "exterior",
      },
      {
        id: 9,
        imgName: Ext3,
        imgType: "exterior",
      },
      {
        id: 10,
        imgName: Dec1,
        imgType: "decor",
      },
      {
        id: 11,
        imgName: Dec2,
        imgType: "decor",
      },
      {
        id: 12,
        imgName: Ext4,
        imgType: "exterior",
      },
      {
        id: 13,
        imgName: Int10,
        imgType: "interior",
      },
      {
        id: 14,
        imgName: Int11,
        imgType: "interior",
      },
      {
        id: 15,
        imgName: Int12,
        imgType: "interior",
      },
      {
        id: 16,
        imgName: Int13,
        imgType: "interior",
      },
      {
        id: 17,
        imgName: Ext5,
        imgType: "exterior",
      },
      {
        id: 18,
        imgName: Ext6,
        imgType: "exterior",
      },
      {
        id: 19,
        imgName: Int14,
        imgType: "interior",
      },
    ],

    category: [
      {
        id: 1,
        categoryName: "All Categories",
        link: "/portfolio/all",
        status: "active",
        url: "all",
      },

      {
        id: 2,
        categoryName: "Interior",
        link: "/portfolio/interior",
        status: "",
        url: "interior",
      },

      {
        id: 3,
        categoryName: "Exterior",
        link: "/portfolio/exterior",
        status: "",
        url: "exterior",
      },

      {
        id: 4,
        categoryName: "Decor",
        link: "/portfolio/decor",
        status: "",
        url: "decor",
      },
    ],
  };

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;

import React, { Component } from "react";
import Branding from "./Branding";
import Menu from "./Menu";
import "./Contact.css";
import Footer from "./Footer";
import * as emailjs from "emailjs-com";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      showMessage: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitForm(event) {
    event.preventDefault();
    emailjs.init("user_SAjp9ABT19lAF0e3h3fJl");
    emailjs.send("gmail", "template_1MU8jRXl", {
      reply_to: "metzler.dd@gmail.com",
      from_name: this.state.name,
      to_name: "Katja",
      message_html: this.state.message + " " + this.state.email
    });
    this.setState({ name: "", email: "", message: "", showMessage: true });

    setTimeout(
      function() {
        this.setState({ showMessage: false });
      }.bind(this),
      2000
    );
  }

  render() {
    return (
      <div>
        <Branding />
        <Menu />
        <div className="contact-form">
          <form>
            {this.state.showMessage ? (
              <h2 className="alert">Your message has been sent! Thank you!</h2>
            ) : (
              <>
                <h2 className="header">Write me a message!</h2>
                <p>
                  <label>Name</label>
                  <input
                    type="text"
                    value={this.state.name}
                    onChange={this.handleChange}
                    className="textInput"
                    name="name"
                    id="name"
                  />
                </p>
                <p>
                  <label>Email</label>
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className="textInput"
                    name="email"
                    id="email"
                  />
                </p>
                <p>
                  <label>Message</label>
                  <textarea
                    type="text"
                    value={this.state.message}
                    onChange={this.handleChange}
                    className="textInput"
                    name="message"
                    id="message"
                  />
                </p>
                <p>
                  <button
                    type="submit"
                    onClick={this.submitForm}
                    id="btn-submit"
                  >
                    Submit
                  </button>
                </p>
              </>
            )}
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

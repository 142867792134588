import React, { Component } from "react";
import "./Branding.css";

export default class Branding extends Component {
  render() {
    return (
      <div className="branding">
        <div className="branding-text">Ecaterina Metzler</div>
      </div>
    );
  }
}

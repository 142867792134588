import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Menu.css";
import { Consumer } from "../context";

export default class Menu extends Component {
  render() {
    return (
      <Consumer>
        {value => {
          return (
            <div>
              {" "}
              <div className="menu">
                <Link className="menu-item black-bg" to="/">
                  Home
                </Link>
                <Link className="menu-item black-bg" to="/portfolio/all">
                  Portfolio
                </Link>
                <Link className="menu-item black-bg" to="/contact">
                  Contact
                </Link>
              </div>
            </div>
          );
        }}
      </Consumer>
    );
  }
}

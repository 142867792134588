import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Home.css";

import Interior from "../img/int6.jpg";
import Exterior from "../img/ext2.jpg";
import Decor from "../img/dec1.jpg";

import { Consumer } from "../context";

const style_left = {
  background: `url(${Interior}) center center no-repeat`,
  height: "100%",
  backgroundSize: "cover"
};

const style_center = {
  background: `url(${Exterior})  center center no-repeat`,
  height: "100%",
  backgroundSize: "cover"
};

const style_right = {
  background: `url(${Decor}) center no-repeat`,
  height: "100%",
  backgroundSize: "cover"
};

class Home extends Component {
  render() {
    return (
      <Consumer>
        {() => {
          return (
            <div className="main-container">
              <div className="split left" style={style_left}>
                <div id="bg-left">
                  <h1 id="left-img">Interior</h1>
                  <Link
                    to="/portfolio/interior"
                    className="button-start"
                    id="2"
                  >
                    View More
                  </Link>
                </div>
              </div>

              <div className="split center" style={style_center}>
                <div id="bg-center">
                  <h1 id="center-img">Exterior</h1>
                  <Link
                    to="/portfolio/exterior"
                    className="button-start"
                    id="3"
                  >
                    View More
                  </Link>
                </div>
              </div>

              <div className="split right" style={style_right}>
                <div id="bg-right">
                  <h1 id="right-img">Decor</h1>
                  <Link to="/portfolio/decor" className="button-start" id="4">
                    View More
                  </Link>
                </div>
              </div>
            </div>
          );
        }}
      </Consumer>
    );
  }
}

export default Home;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { Consumer } from "../context";

class Navbar extends Component {
  render() {
    return (
      <Consumer>
        {value => {
          const { category } = value;
          category.forEach(cat => {
            if (this.props.active_category === cat.url) {
              cat.status = "active";
            } else {
              cat.status = "";
            }
          });

          return (
            <div>
              <div className="category">
                <ul>
                  {category.map(cat => (
                    <li className={cat.status} key={cat.id}>
                      <Link to={cat.link} className="categoryLink" id={cat.id}>
                        {cat.categoryName}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        }}
      </Consumer>
    );
  }
}
export default Navbar;

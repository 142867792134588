import React, { Component } from "react";
import "./Portfolio.css";
import Branding from "./Branding";
import Menu from "./Menu";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";

import { Consumer } from "../context";

class Portfolio extends Component {
  render() {
    return (
      <Consumer>
        {value => {
          const state_images = { images: value.images };
          if (this.props.match.params.category !== "all") {
            state_images.images = state_images.images.filter(
              img => img.imgType === this.props.match.params.category
            );
          }
          const active_category = this.props.match.params.category;
          return (
            <div>
              <Branding />
              <Menu />
              <Navbar active_category={active_category} />
              <div className="cont-portfolio">
                {state_images.images.map(image => (
                  <div className="cont-portfolio" key={image.id}>
                    <Link
                      to={`/portfolio/${active_category}/${image.id}`}
                      className="picture-box"
                    >
                      <img
                        className="picture"
                        src={image.imgName}
                        alt="3D-Visualisation"
                      />
                    </Link>
                  </div>
                ))}
              </div>
              <Footer />
            </div>
          );
        }}
      </Consumer>
    );
  }
}

export default Portfolio;

import React, { Component } from "react";
import { Consumer } from "../context";
import "./Gallery.css";
import { Link } from "react-router-dom";

export default class Gallery extends Component {
  componentWillMount() {
    document.addEventListener("keydown", this.onKeyDown.bind(this));
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown.bind(this));
  }

  onKeyDown(event) {
    console.log(event.key);
  }
  render() {
    return (
      <Consumer>
        {value => {
          const stateImages = { images: value.images };

          if (this.props.match.params.category !== "all") {
            stateImages.images = stateImages.images.filter(
              img => img.imgType === this.props.match.params.category
            );
          }

          const currentImage = stateImages.images.filter(
            img => img.id === parseFloat(this.props.match.params.imgid)
          );

          const allImagesIds = stateImages.images.map(image => image.id);
          const currentIndex = allImagesIds.indexOf(currentImage[0].id);
          const lastIndex = allImagesIds.length - 1;

          // previous
          let previousIndex = currentIndex - 1;
          if (previousIndex < 0) {
            previousIndex = lastIndex;
          }
          const previousImage = stateImages.images.filter(
            img => img.id === allImagesIds[previousIndex]
          );

          // next
          let nextIndex = currentIndex + 1;
          if (nextIndex > lastIndex) {
            nextIndex = 0;
          }
          const nextImage = stateImages.images.filter(
            img => img.id === allImagesIds[nextIndex]
          );

          return (
            <div className="gallery" onClick={this.nextImage}>
              <img
                className="imageGallery"
                src={currentImage[0].imgName}
                alt="3D-Visualisation"
              />
              <Link
                to={`/portfolio/${this.props.match.params.category}/${nextImage[0].id}`}
              >
                <i className="fas fa-3x fa-chevron-right previous arrow" />
              </Link>
              <Link
                to={`/portfolio/${this.props.match.params.category}/${previousImage[0].id}`}
              >
                <i className="fas fa-3x fa-chevron-left next arrow" />
              </Link>
              <Link to={`/portfolio/${this.props.match.params.category}`}>
                <i className="fas fa-3x fa-times arrow close" />
              </Link>
            </div>
          );
        }}
      </Consumer>
    );
  }
}

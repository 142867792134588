import React, { Component } from "react";
import ScriptTag from "react-script-tag";
import "./Footer.css";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-icon">
          <a href="https://www.instagram.com/katja.metzler/">
            <i className="fab fa-2x fa-instagram" />
          </a>
          <a href="https://www.linkedin.com/in/ecaterina-metzler-007942183/">
            <i className="fab fa-2x fa-linkedin-in" />
          </a>
          <a href="https://www.behance.net/katja_metzler">
            <i className="fab fa-2x fa-behance-square" />
          </a>
        </div>
        <div className="footer-text">Ecaterina Metzler 2019</div>
        <ScriptTag
          isHydrating={false}
          type="text/javascript"
          src="https://ofg-studium.de/images/certificate/utils/cert.php?x=83f9b518-1d82-11e9-b868-005056a71e55"
        />
      </div>
    );
  }
}
